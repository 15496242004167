import {
  TechRadarLoaderResponse,
  TechRadarApi,
} from '@backstage-community/plugin-tech-radar';

const techRadar: TechRadarLoaderResponse = {
  entries: [
    // JavaScript
    {
      timeline: [
        {
          date: new Date('2014-04-02'),
          description:
            'With the introduction of TypeScript 1.0, JavaScript should rather be avoided in favour of TypeScript where possible',
          moved: -1,
          ringId: 'sometimes',
        },
        {
          date: new Date('2008-01-01'),
          description: 'A good language to be used in many cases',
          moved: 0,
          ringId: 'adopt',
        },
      ],
      key: 'javascript',
      id: 'javascript',
      title: 'JavaScript',
      quadrant: 'languages',
      links: [
        {
          url: 'https://www.javascript.com/',
          title: 'JavaScript',
        },
      ],
      description:
        'JavaScript is a scripting language initially intended for the web as a DOM manipulation tool. It has since evolved a trmendous amount.',
    },
    // TypeScript
    {
      timeline: [
        {
          moved: 0,
          ringId: 'adopt',
          date: new Date('2014-04-02'),
          description:
            'With the release of TypeScript 1.0, it should be used when possible/necessary.',
        },
      ],
      key: 'typescript',
      id: 'typescript',
      title: 'TypeScript',
      quadrant: 'languages',
      description:
        'TypeScript is a programming language that adds static typing with optional type annotations to JavaScript.',
    },
    // Webpack
    {
      timeline: [
        {
          date: new Date('2018-05-03'),
          description:
            'With the release of Parcel, Webpack is too cumbersome for the same functionality',
          moved: -1,
          ringId: 'hold',
        },
        {
          date: new Date('2014-02-19'),
          description: 'A good choice for module bundling with many options',
          moved: 0,
          ringId: 'adopt',
        },
      ],
      links: [
        {
          url: 'https://webpack.js.org/',
          title: 'Webpack',
        },
      ],
      key: 'webpack',
      id: 'webpack',
      title: 'Webpack',
      quadrant: 'frameworks',
      description: 'Webpack is a module bundler for JavaScript',
    },
    // Parcel
    {
      timeline: [
        {
          date: new Date('2018-05-03'),
          description: 'Simple JavaScript/TypeScript module bundler',
          moved: 0,
          ringId: 'adopt',
        },
      ],
      links: [
        {
          url: 'https://parceljs.org/',
          title: 'Parcel',
        },
      ],
      key: 'parcel',
      id: 'parcel',
      title: 'Parcel',
      quadrant: 'frameworks',
      description: 'Parcel is a module bundler for JavaScript',
    },
    // React
    {
      timeline: [
        {
          moved: 0,
          ringId: 'adopt',
          date: new Date('2013-05-29'),
        },
      ],
      links: [
        {
          url: 'https://reactjs.org/',
          title: 'React',
        },
      ],
      key: 'react',
      id: 'react',
      title: 'React',
      quadrant: 'frameworks',
    },
    // GitHub Actions
    {
      timeline: [
        {
          ringId: 'adopt',
          date: new Date('2018-08-16'),
          description:
            'GitHub Actions is release and is a good free CI/CD tool.',
        },
      ],
      links: [
        {
          url: 'https://github.com/features/actions',
          title: 'GitHub Actions',
        },
      ],
      key: 'github-actions',
      id: 'github-actions',
      title: 'GitHub Actions',
      quadrant: 'infrastructure',
    },
  ],
  quadrants: [
    { id: 'infrastructure', name: 'Infrastructure' },
    { id: 'frameworks', name: 'Frameworks' },
    { id: 'languages', name: 'Languages' },
    { id: 'process', name: 'Process' },
  ],
  rings: [
    {
      color: '#5BA300',
      description: 'Should be adopted. Tried and tested.',
      id: 'adopt',
      name: 'ADOPT',
    },
    {
      color: '#9BA000',
      description: 'Can be used, but there might be better alternatives',
      id: 'sometimes',
      name: 'SOMETIMES',
    },
    {
      color: '#009EB0',
      description: 'Currently being tested.',
      id: 'trial',
      name: 'TRIAL',
    },
    {
      color: '#C7BA00',
      description: 'Should be tested by a single developer/group in isolation.',
      id: 'assess',
      name: 'ASSESS',
    },
    {
      color: '#E09B96',
      description: 'Tried, and not to be implemented.',
      id: 'hold',
      name: 'HOLD',
    },
  ],
};

export class RHDHVTechRadarApi implements TechRadarApi {
  async load() {
    return techRadar;
  }
}
